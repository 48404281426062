import './assets/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import router from './router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'
import axios from 'axios'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.withCredentials = true

app.use(createPinia())
app.use(router)
const vuetify = createVuetify({
  theme: {
    defaultTheme: 'ogotoTheme',
    themes: {
      ogotoTheme: {
        dark: false,
        colors: {
          primary: '#FF5E1F',
          secondary: '#765848',
          accent: '#ff8f00',
          error: '#ba1a1a',
          background: '#fffbff'
        }
      }
    }
  },
  components,
  directives,
  defaults: {
    VTextField: {
      variant: 'underlined'
    },
    VSelect: {
      variant: 'underlined'
    },
    VAutocomplete: {
      variant: 'underlined'
    },
    VBtn: {
      ripple: false,
      elevation: '0',
      color: 'primary'
    }
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  }
})

Sentry.init({
  app,
  environment: import.meta.env.MODE,
  dsn: 'https://193e5241ab69881c54b1451dd7b21f38@o4506275922116608.ingest.sentry.io/4506275924344832',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.ogoto\.co/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(Notifications)
app.use(vuetify)
console.log('env:', import.meta.env.MODE)
app.mount('#app')
