<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { motion } from 'motion-v'
import { useRes } from '@/composables/useRes'

const { smAndUp } = useDisplay()
</script>

<template>
  <div class="main-banner flex flex-col py-12 lg:py-24">
    <motion.div
      :initial="{ opacity: 0, y: 40, filter: 'blur(10px)' }"
      :whileInView="{ opacity: 1, y: 0, filter: 'blur(0px)' }"
      :transition="{
        delay: 0.3,
        duration: 0.8,
        ease: 'easeInOut'
      }"
      class="flex flex-col items-center"
    >
      <h1 class="title-hero text-center">접속을 넘어 접촉으로<br />오프라인 모임 SNS, 오고토</h1>
    </motion.div>
    <motion.div
      :initial="{ opacity: 0, y: 60, filter: 'blur(10px)' }"
      :whileInView="{ opacity: 1, y: 0, filter: 'blur(0px)' }"
      :transition="{
        delay: 0.5,
        duration: 0.8,
        ease: 'easeOut'
      }"
      class="flex flex-col items-center mt-12 "
    >
      <p class="subheading text-default-secondary ">새로운 오프라인 모임 문화</p>
      <v-btn class="mt-5">앱 다운받기</v-btn>
    </motion.div>
    <motion.div
      :initial="{ transform: 'rotateX(-30deg)', opacity: 0, filter: 'blur(10px)' }"
      :whileInView="{ transform: 'rotateX(0)',  opacity: 1, filter: 'blur(0px)' }"
      style="transform-origin: bottom;"
      :transition="{
        delay: 0.5,
        duration: 0.8,
        ease: 'easeOut'
      }"
      class="flex justify-center mt-10"
    >
      <img :src="useRes('a1.png')" alt="screenshot" />
    </motion.div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/assets/mixins';

h1 {
  font-size: 42px;
}

@include md {
  h1 {
    font-size: 64px;
  }
}
</style>
