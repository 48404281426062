<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import CoreView from '@/components/core/CoreView.vue'
import CoreDrawer from '@/components/core/CoreDrawer.vue'
import AppHeader from '@/components/core/AppHeader.vue'
import MainBanner from '@/components/home/MainBanner.vue'
import WhyUse from '@/components/home/WhyUse.vue'
import WhatUse from '@/components/home/WhatUse.vue'
import WhyMake from '@/components/home/WhyMake.vue'
import { useRouter } from 'vue-router'
import { useAuth } from '@/composables/useFirebase'
import axios from 'axios'
import { notifyError } from '@/lib/notify'
import { onMounted } from 'vue'
import AppFooter from '@/components/core/AppFooter.vue'
import HowTo from '@/components/home/HowTo.vue'

const profileStore = useProfileStore()
const { me } = profileStore
const router = useRouter()
const { signIn, getRedirect } = useAuth()
</script>

<template>
  <div class="home overflow-x-hidden">
    <AppHeader />
    <MainBanner />
    <WhatUse />
    <app-footer />
    <!--      <v-btn class="mt-8 !block" size="x-large" width="253" @click="goLogin">시작하기</v-btn>-->
  </div>
</template>
<style scoped lang="scss">
.home {
  background-color: white;
  padding-top: 64px;
}

.logo {
  font-size: 20px;
}
</style>
