import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignupView from '@/views/SignupView.vue'
import LoginView from '@/views/LoginView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      children: [
        {
          name: 'app',
          path: '/',
          component: () => import('@/views/AppView.vue')
        }
      ]
    },
    {
      path: '/checkout',
      name: 'checkout',
      children: [
        {
          name: 'checkoutIndex',
          path: '',
          component: () => import('@/views/CheckoutView.vue')
        },
        {
          name: 'checkoutSuccess',
          path: 'success',
          component: () => import('@/views/checkout/SuccessView.vue')
        },
        {
          name: 'checkoutFail',
          path: 'fail',
          component: () => import('@/views/checkout/FailView.vue')
        }
      ]
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignupView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/user-data-remove',
      name: 'user-data-remove',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/etc/UserDataRemove.vue')
    }
  ]
})

export default router
