<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout'
import { storeToRefs } from 'pinia'
import Logo from '@/assets/logo_typo.svg'

const layoutStore = useLayoutStore()
const { drawer } = storeToRefs(layoutStore)
</script>

<template>
  <header class="app-bar flex justify-between" >
    <logo width="113" height="39" />
    <v-btn>앱 다운받기</v-btn>
  </header>

</template>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  height: 64px;
  padding: 12px 32px;
  z-index: 99;
}
</style>
